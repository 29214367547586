import './css/mainpage.css';
import Logo from './images/logo.png';
import tiktok from './images/tiktok.png';
import React, { useState, useRef, useEffect } from 'react';

import snapchaticon from './images/icons/Snapchat_App_Icon.png';
import facebookicon from './images/icons/facebook-icon-512x512-seb542ju.png';
import linkedinicon from './images/icons/LinkedIn_icon.svg.png';
import Xicon from './images/icons/x-social-media-logo-icon.webp';
import whatsappicon from './images/icons/3670051.png';
import massengericon from './images/icons/3d-render-meta-chat-messenger-facebook-messenger-icon-bubble-isolated-on-transparent-background-free-png.png';
import emailicon from './images/icons/327339.png';


import pic1 from './images/1_0.webp';
import pic2 from './images/2_1.webp';
import pic3 from './images/3_2.webp';
import pic4 from './images/4_3.webp';
import pic5 from './images/5_4.webp';
import pic6 from './images/6_5.webp';
import pic7 from './images/7_6.webp';
import pic8 from './images/8_7.webp';


import pinable from './images/pinabble.png';
import cherry from './images/cherry.png';





function MainPage(){
    const [linkValue] = useState("https://pando-pando.info/");
    const [buttonText, setButtonText] = useState("نسخ");
    const [shareOverlayActive, setShareOverlayActive] = useState(false);
    const shareOverlayRef = useRef();


    const copyLink = () => {
        const copyText = document.getElementById("copy-link-input");
        copyText.select();
        document.execCommand("copy");
        setButtonText("تم النسخ!");
        setTimeout(() => setButtonText("نسخ"), 2000); // Reset button text after 2 seconds
    };

    const openShareOverlay = () => {
        setShareOverlayActive(true);
    };
    
    const closeShareOverlay = () => {
        setShareOverlayActive(false);
    };


    const handleClickOutside = (event) => {
        if (shareOverlayRef.current && !shareOverlayRef.current.contains(event.target)) {
        closeShareOverlay();
        }
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    

    
    return(
        <>


        <div className='backgroundimg'></div>
        <div className={`share-overlay ${shareOverlayActive ? 'active' : ''}`}>
                                <div className='share-overlay-content'>
                                    <div  ref={shareOverlayRef}>
                                        <h1> شارك الرابط عن طريق </h1>
                                        <button className="close-share" onClick={closeShareOverlay}> <i className="las la-times"></i> </button>

                                        <ul>

                                            <li>
                                                <a href="https://www.snapchat.com/scan?attachmentUrl=https://pando-pando.info%2F&utm_source=pando" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر سنابشات
                                                    <img src={snapchaticon} alt="social media icon" />

                                                </a>
                                            </li>



                                            <li>
                                                <a href="https://facebook.com/sharer.php?u=https://pando-pando.info/" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر فيسبوك
                                                    <img src={facebookicon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="https://linkedin.com/shareArticle?url=https://pando-pando.info/" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر لينكدان
                                                    <img src={linkedinicon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="https://twitter.com/intent/tweet?url=https://pando-pando.info/" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر تويتر
                                                    <img src={Xicon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="whatsapp://send?text=https://pando-pando.info/" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر واتساب
                                                    <img src={whatsappicon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="fb-messenger://share/?link=https://pando-pando.info/" target="_blank">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر ماسنجر
                                                    <img src={massengericon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="mailto:?body=https://pando-pando.info/">
                                                    <i class="las la-angle-left"></i>
                                                    مشاركة عبر البريد
                                                    <img src={emailicon} alt="social media icon" />
                                                </a>
                                            </li>

                                            <li>
                                                <input type="text" id="copy-link-input" value={linkValue} required readOnly />
                                                <button onClick={copyLink}>{buttonText} <i className="las la-copy"></i></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

            <section className='main-page'>
                <div className='container'>
                    <div className='main-page-content'>
                    <button className="open-share" onClick={openShareOverlay}> <i className="las la-ellipsis-h"></i> </button>

                            <div className='header'>
                                <img src={Logo} alt="logo"  />
                                <h1> <img src={pinable} /> Pando <span> باندو </span> <img src={cherry} />  </h1>
                                <p>  باندو هو وجهتك الأولى للتسوق حيث نقدم لك ضيافة الفواكه بأعلى معايير الجودة والنظافة ، وبأشكال وتصاميم مبتكرة ومميزة  </p>
                            </div>

                            <div className='content'>
                                
                                <h2>   للطلبات عبر الواتساب <i class="lab la-whatsapp"></i></h2>

                                <a aria-label='pando Whatsapp' href="https://wa.me/message/6JGZMSTLRB7OE1" className='whatsapp'> <i class="lab la-whatsapp"></i> الواتساب </a>

                                <h2> لمتابعة حساباتنا في مواقع التواصل الإجتماعي <i class="las la-share-alt"></i> </h2>

                                <a className="snapchat" aria-label='pando SnapChat' href="https://www.snapchat.com/add/pando.p?share_id=s8ky7JFoRdOzklGJDoaZQw&locale=ar_SA@calendar=gregorian" ><i class="lab la-snapchat"></i> سناب شات </a>

                                <a className="twitter" aria-label='pando twitter' href="https://twitter.com/pando_info?s=11&t=gqO-WS6l8qqPNM0qb7UPrw" ><i class="lab la-twitter"></i>تويتر </a>

                                <a className="instagram" aria-label='pando instagram' href="https://instagram.com/pando_info?igshid=YmMyMTA2M2Y=" ><i class="lab la-instagram"></i> انتسقرام </a>

                                <a className="tiktok" aria-label='pando tiktok' href="http://www.tiktok.com/@pando_info" ><img src={tiktok} alt="tiktok" /> تيك توك </a>

                            </div>
                    </div>
                </div>
            </section>

            <div className='images-left'>
            <img src={pic1} alt="pando work images" />
            <img src={pic2} alt="pando work images" />
            <img src={pic3} alt="pando work images" />
            <img src={pic4} alt="pando work images" />

        </div>

        <div className='images-right'>
            <img src={pic5} alt="pando work images" />
            <img src={pic6} alt="pando work images" />
            <img src={pic7} alt="pando work images" />
            <img src={pic8} alt="pando work images" />
        </div>
        </>
    )
}

export default MainPage;