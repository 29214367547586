import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import MainPage from './components/MainPage';

function App() {

return (
    <div className="App">


            <Routes>
            

            <Route path="/" element={
                    <>
                    

                        <MainPage />
                    </>
                } />



        </Routes>


        
    </div>
);
}

export default App;
